import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { Container, Grid, Box } from "@material-ui/core"
import Layout from "../../../components/layout"

const CoursePage  = (props) =>  {
   const { data } = props

   const { edges: posts } = data.allMarkdownRemark

   return (
     <div>
      <Layout>
        <Box mt={8}>
          <Container maxWidth="lg" >
            <Grid container spacing={3} alignItems="center">
              <Grid item sm={12}> 
                <h1>Featured React Posts</h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  {posts &&
                    posts.map(({ node: post }) => (
                      <Link to={post.frontmatter.path}><p>{post.frontmatter.title}</p></Link>
                    ))}
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
       </Layout>
     </div>
   )
}

export default () => (
 <StaticQuery
   query={graphql`
     query CoursePageQuery {
       allMarkdownRemark(
         limit: 2,
         sort: { order: DESC, fields: [frontmatter___date] },
         filter: {fileAbsolutePath: {regex: "/featured-post/"  }}
       ) {
         edges {
           node {
             excerpt(pruneLength: 100)
             frontmatter {
               title
               path
               date(formatString: "MMMM DD, YYYY")
             }
           }
         }
       }
     }
   `}
   render={data => <CoursePage data={data} />}
 />
)
